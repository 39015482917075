import { Actions } from "./types";
import { IAction, IStore } from "./interface";

export const Reducer = (
  state: IStore,
  { type, payload }: IAction,
) => {
  switch (type) {
    case Actions.CHANGE_STORE:
      return {
        ...state,
        ...payload,
      }

    default:
      return state;
  }
};
