import {
  Box,
  Button,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router";
import { createHmacString, detectDevice } from "../../helpers";
import { useContext, useState } from "react";
import { Context } from "../../store";
import GoogleWalletButton from "../../components/google-wallet-button";
import AppleWalletButton from "../../components/apple-wallet-button";

const SetWallet = () => {
  const navigate = useNavigate()
  const device = detectDevice()
  const {branch_id, user} = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [passDownloadded, setPassDownloadded] = useState(false)

  const handleSetWallet = async () => {
    const params = `?branch_id=1480427899071762432&user_id=1716384248568483840`;
    try {
      setLoading(true)
      const token = createHmacString(new Date().getTime().toString())

      switch (device) {
        case 'ios':
          const responseIOS = await fetch(process.env.REACT_APP_API_URL + `/wallet/pass/ios${params}`, {
            headers: {
              'Content-Type': 'application/vnd.apple.pkpass',
              'Authorization': token,
            },
          })
          const buffer = await responseIOS.arrayBuffer();
          const bytes = new Uint8Array(buffer);
          const file = new Blob([bytes], {
            type: 'application/vnd.apple.pkpass',
          })
          console.log(file)
          const fileURL = URL.createObjectURL(file)
          const link = document.createElement('a')
          link.href = fileURL
          link.download = fileURL.split('/').pop() || '.pkpass'
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(fileURL)
          setPassDownloadded(true)
          break;
        case 'android':
        case 'desktop':
          const response = await fetch(process.env.REACT_APP_API_URL + `/wallet/pass/android${params}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
            },
          })
          const json = await response.json()
          const data = json.data
          if (data.link) {
            window.location.replace(data.link)
          }
          break;
      }
    } catch (e) {

    } finally {
      setLoading(false)
    }
  }

  return (
    <Stack spacing={4}>
      <Box stroke="teal.600" justifyContent="center" display="flex">
        <svg stroke="teal.600" fill="none" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true" height="200px"
             width="200px">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"></path>
        </svg>
      </Box>
      {!passDownloadded ? (
        <>
          <Box>
            <Heading size="md" as="h6" textAlign="center">Для хранения карт необходимо приложение Wallet</Heading>
          </Box>
          {device === 'ios' ? (
            <AppleWalletButton
              onClick={handleSetWallet}
              textTransform="uppercase"
              colorScheme='teal'
              variant='outline'
              isLoading={loading}
              type="submit"
            />
            ) : (
            <GoogleWalletButton
              onClick={handleSetWallet}
              textTransform="uppercase"
              colorScheme='teal'
              variant='outline'
              isLoading={loading}
              type="submit"
            />
          )}
        </>
      ) : (
        <>
          <Box>
            <Heading size="md" as="h6" textAlign="center">Ключ скачан в ваш телефон</Heading>
          </Box>
          <Button
            textTransform="uppercase"
            type="submit"
            rightIcon={<ArrowForwardIcon/>}
            colorScheme='teal'
            variant='outline'
            isLoading={loading}
            onClick={() => window.close()}
          >
            Закрыть
          </Button>
        </>
      )}
    </Stack>
  )
}

export default SetWallet