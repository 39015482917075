import React, { useContext, useEffect } from "react"
import { useFormik } from "formik"
import {
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  Stack,
  Text
} from "@chakra-ui/react"
import * as yup from 'yup'
import { ArrowForwardIcon } from "@chakra-ui/icons"
import { useLocation, useNavigate } from "react-router"
import DatePicker from 'react-datepicker'
import { Context } from "../../store";
import { format, parse } from 'date-fns'
import { createHmacString } from "../../helpers";

const FillForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { branch_name, phone, user, changeStore } = useContext(Context)

  const formik = useFormik({
    initialValues: {
      birthday: '',
      first_name: '',
      last_name: '',
    },
    validationSchema: yup.object().shape({
      first_name: yup.string().required('Пожалуйста заполните поле'),
      last_name: yup.string().required('Пожалуйста заполните поле'),
      birthday: yup.date().required('Пожалуйста заполните поле'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const body = {
        ...values,
        phone_number: '+' + phone.replace(/[^\d]/g, ''),
        birthday: format(values.birthday, 'dd-MM-yyyy'),
      }
      try {
        const token = createHmacString(new Date().getTime().toString())
        const response = await fetch(process.env.REACT_APP_API_URL + `/wallet/front/user/info`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify(body),
        })
        const json = await response.json()
        const data = json.data

        if (data.id) {
          changeStore({ user: data })
          navigate({
            pathname: '/set-wallet',
            search: location.search
          })
        }
      } catch (e) {

      } finally {
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (user) {
      formik.setFieldValue("first_name", user.first_name)
      formik.setFieldValue("last_name", user.last_name)
      if (user.birthday) {
        const birthday = parse(user.birthday, 'dd-MM-yyyy', new Date)
        formik.setFieldValue("birthday", birthday)
      }
    }
  }, [user])

  return (
    <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>
      <Stack spacing={4}>
        <Box marginBottom={4}>
          <Heading size="md" as="h2" textAlign="center">Осталось заполнить поля</Heading>
          <Text textAlign="center">И вы сможете скачать карту лояльности</Text>
        </Box>
        <InputGroup>
          <Input
            value={formik.values.first_name || ''}
            onChange={formik.handleChange}
            name="first_name"
            placeholder='Введите Имя'
            required
          />
        </InputGroup>
        <InputGroup>
          <Input
            value={formik.values.last_name || ''}
            onChange={formik.handleChange}
            name="last_name"
            placeholder='Введите Фамилию'
            required
          />
        </InputGroup>
        <DatePicker
          placeholderText="Укажите дату рождения"
          selected={formik.values.birthday ? new Date(formik.values.birthday) : null}
          required
          onChange={(date) => {
            formik.setFieldValue('birthday', date)
          }}
          dateFormat="dd-MM-yyyy"
          customInput={<Input disabled required type='text' placeholder='Дата рождения'/>}
        />
        <Button loadingText="Сохранение данных" isLoading={formik.isSubmitting} type="submit" rightIcon={<ArrowForwardIcon/>} colorScheme='teal' variant='outline'>
          Продолжить
        </Button>
      </Stack>
    </form>
  )
}

export default FillForm