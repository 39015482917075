import styled from '@emotion/styled'
import { FC } from "react";
import { ButtonProps, Button } from "@chakra-ui/react";

const StyledAppleWalletButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-content: center;
  
  &:hover {
    background-color: #333;
  }
  
  i {
    height: 20px;
    margin-right: 8px;
  }
`

const AppleWalletButton: FC<ButtonProps> = (props) => {
  return (
    <StyledAppleWalletButton {...props}>
      <i className="fab fa-apple"></i>
      <span>Добавить карту в wallet</span>
    </StyledAppleWalletButton>
  )
}

export default AppleWalletButton