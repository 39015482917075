import styled from '@emotion/styled'
import { FC } from "react";
import { ButtonProps, Button } from "@chakra-ui/react";

const StyledGoogleWalletButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  justify-content: center;
  
  &:hover {
    background-color: #3c4043;
  }
  
  img {
    height: 18px;
    margin-right: 8px;
  }
`

const GoogleWalletButton: FC<ButtonProps> = (props) => {
  return (
    <>
      <StyledGoogleWalletButton {...props}>
          <img src="https://www.gstatic.com/instantbuy/svg/dark_gpay.svg" alt="Google Wallet"/>
          <span>Добавить карту в wallet</span>
      </StyledGoogleWalletButton>
    </>
  )
}

export default GoogleWalletButton