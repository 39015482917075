import { RouteObject } from 'react-router'
import { Loadable } from './components/Loadable'
import { lazy } from 'react'

import Layout from "./layout";
import FindUser from "./pages/FindUser";
import FillForm from "./pages/FillForm";
import SetWallet from "./pages/SetWallet";

export const router: RouteObject[] = [
  {
    path: '*',
    element: <h1></h1>
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <FindUser />,
      },
      {
        path: 'fill-form',
        element: <FillForm />,
      },
      {
        path: 'set-wallet',
        element: <SetWallet />,
      }
    ]
  },
]
