import * as React from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import {useRoutes} from 'react-router'
import {router} from './router'
import { Store } from "./store";

export const App = () => {
  const content = useRoutes(router)
  // bb
  return (
    <ChakraProvider theme={theme}>
      <Store>
        {content}
      </Store>
    </ChakraProvider>
  )
}
