import { useFormik } from "formik"
import {
  Box,
  Button, Checkbox,
  Heading,
  Input,
  InputGroup,
  InputLeftElement, Stack, Text
} from "@chakra-ui/react"
import * as yup from 'yup'
import { ArrowForwardIcon, PhoneIcon } from "@chakra-ui/icons"
import { Link } from '@chakra-ui/react'
import { useLocation, useNavigate } from "react-router"
import { useContext } from "react"
import { Context } from "../../store"
import { useMask } from "@react-input/mask"
import { createHmacString } from "../../helpers";

const FindUser = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { branch_name, changeStore } = useContext(Context)
  const phoneRef = useMask({
    mask: '+7 ___ ___ __ __',
    replacement: { _: /\d/ },
  })

  const formik = useFormik({
    initialValues: {
      phone: '',
      agreed: false,
    },
    validationSchema: yup.object().shape({
      phone: yup.string().required('Пожалуйста заполните поле'),
      agreed: yup.boolean().required(''),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const token = createHmacString(new Date().getTime().toString())
        const response = await fetch(process.env.REACT_APP_API_URL + `/wallet/front/user?phone=+${values.phone.replace(/[^\d]/g, '')}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          }
        })
        const json = await response.json()
        const data = json.data

        if (data.hasOwnProperty("id")) {
          changeStore({ phone: values.phone, user: data })
          navigate({
            pathname: '/fill-form',
            search: location.search
          })
        }

      } catch (e) {

      } finally {
        setSubmitting(false)
      }

    },
  })

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <Stack spacing={4}>
        <Heading marginBottom={4} as="h2" textAlign="center" color="teal.500" size="lg">{branch_name}</Heading>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <PhoneIcon color='gray.300' />
          </InputLeftElement>
          <Input
            disabled={formik.isSubmitting}
            ref={phoneRef}
            type='tel'
            required
            placeholder='Введите номер телефона'
            value={formik.values.phone || ''}
            onChange={formik.handleChange}
            name="phone"
            // error={formik.touched.phone && formik.errors.phone}
          />
        </InputGroup>

        <Button loadingText="Поиск юзера" isLoading={formik.isSubmitting} type="submit" rightIcon={<ArrowForwardIcon />} colorScheme='teal' variant='outline'>
          Продолжить
        </Button>
        <Box display="flex" gap={2} flexDirection="row">
          <Checkbox name="agreed" onChange={formik.handleChange} checked={formik.values.agreed} size="lg" flexShrink={0} />
          <Text fontSize="xs" lineHeight="120%">
            Нажимая на кнопку "Продолжить" Вы соглашаетесь с <Link color="teal.600" href="https://sagi.kz/terms.html">Условиями публичной оферты</Link> и даёте своё согласие на обработку данных.
          </Text>
        </Box>
      </Stack>
    </form>
  )
}

export default FindUser