import CryptoJS from 'crypto-js'

export const createHmacString = (ts: string) => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_SECRET_KEY!)
  const timestamp = CryptoJS.enc.Utf8.parse(ts)
  const hmac = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(timestamp, key))

  //  const hmac = CryptoJS.HmacSHA256(ts, privateKey).toString(CryptoJS.enc.Hex)
  return hmac;
}

export const detectDevice = (): 'ios' | 'android' | 'desktop' => {
  let userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.search("iphone") > -1 ||
    userAgent.search("ipad") > -1 ||
    userAgent.search("ipod") > -1) {
    return "ios";
  }

  if (userAgent.search("android") > -1) {
    return "android";
  }

  return "desktop";
}