import { Outlet } from "react-router";
import { Center, Container, VStack, HStack, Box } from "@chakra-ui/react";
import LogoImg from '../assets/logo.png'
import React from "react";

const Layout = () => {
  return (
    <Container>
      <Center>
        <VStack height="100vh" width="sm">
          <Box
            marginTop={10}
            style={{
              width: 60,
              height: 60
            }}
          >
            <img src={LogoImg} style={{ maxWidth: '100%' }} alt="pass.sagi.kz"/>
          </Box>
          <HStack paddingTop={5} justifyContent="center" width="100%">
            <Outlet />
          </HStack>
        </VStack>
      </Center>

    </Container>
  )
}

export default Layout