import React, { useEffect, useReducer } from "react";
import { Reducer } from "./reducer";
import { IAction, IStore } from "./interface";
import { useQuery } from "../hooks";
import { Actions } from "./types";
import { useNavigate } from "react-router-dom";

const initialState: IStore = {
  branch_name: '',
  branch_id: '',
  phone: '',
  user: null,
  changeStore: () => {},
}

export const Context = React.createContext<IStore>(initialState)

export const Store: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const query = useQuery()
  const navigate = useNavigate()

  useEffect(() => {
    if (!query.get('branch_name') || !query.get('branch_id')) {
      navigate(-1)
    }
  }, [])

  useEffect(() => {
    changeStore({
      branch_name: query.get('branch_name') || '',
      branch_id: query.get('branch_id') || '',
    })
  }, [])

  const changeStore = (payload: Partial<IStore>) => {
    dispatch({ type: Actions.CHANGE_STORE, payload })
  }

  const [state, dispatch] = useReducer<React.Reducer<IStore, IAction>>(
    Reducer,
    initialState,
  );
  console.log(state, "state")
  return <Context.Provider value={{ ...state, changeStore }}>{children}</Context.Provider>
}